<template>
  <div class="home overflow-x-hidden">
    <img
      src="@/assets/background/background_main.png"
      class="w-full min-w-[825px] h-auto fixed -z-50"
      alt=""
    />
    <!-- Header -->
    <div
      class="h-[90px] bg-transparent container px-[15px] mx-auto flex justify-between items-center"
    >
      <p class="cursor-pointer text-h3-bold md:text-h2-bold text-sk-default2">정책공유AI플랫폼</p>
      <div class="hidden md:flex gap-[70px]">
        <p class="cursor-pointer text-h3-bold text-sk-default2">교육</p>
        <p class="cursor-pointer text-h3-bold text-sk-default2">정책</p>
        <p class="cursor-pointer text-h3-bold text-sk-default2">뉴스</p>
        <p class="cursor-pointer text-h3-bold text-sk-primary animate-pulse">AI대변인</p>
        <img class="cursor-pointer" src="@/assets/icons/search.svg" alt="search" />
      </div>
    </div>
    <div class="bg-transparent container px-[15px] mx-auto" id="my-title">
      <p class="text-title-36 lg:text-title-52 text-sk-default2 pt-[83px] md:pt-[10vw] mb-[7px]">
        정책공유플랫폼
      </p>
      <p class="text-h3-regular lg:text-h1-regular text-sk-default2 pb-[100px] md:pb-[12vw]">
        정책공유플랫폼
      </p>
    </div>
    <div class="bg-white w-full pt-[70px] pb-[120px] relative z-10">
      <img src="@/assets/background/Ellipse1.svg" class="absolute top-0 right-0 z-0" />
      <img src="@/assets/background/Ellipse2.svg" class="absolute bottom-0 left-0 z-0" />
      <div class="container mx-auto slide-in-bottom px-[10px] relative z-30">
        <div>
          <div class="flex gap-[16px] items-center">
            <div class="w-[4px] h-[50px] bg-sk-primary"></div>
            <div class="text-h1-bold md:text-title-36 md:!text-[42px]">교육</div>
          </div>
          <div class="mt-[40px] flex flex-col 2xl:flex-row justify-between gap-[30px]">
            <div>
              <p class="text-h3-bold md:text-h2-bold pl-[10px]">국회 이야기</p>
              <div
                class="md:w-[469px] md:h-[278px] mt-[16px] border bg-sk-box002 hover:bg-sk-box001 border-sk-border rounded-[20px] pt-[39px] pb-[60px] pl-[31px] bg-cover bg-right-bottom"
                :style="{ 'background-image': 'url(' + whatUrl + ')' }"
              >
                <p class="text-h2-bold md:text-h1-bold md:!font-semibold">무슨 일을 할까요?</p>
                <p class="text-h3-regular mt-[24px]">
                  국회에서는<br />
                  어떤 일을 하는 지<br />
                  함께 알아볼까요?
                </p>
              </div>
            </div>
            <div>
              <p class="text-h3-bold md:text-h2-bold pl-[10px]">콘텐츠</p>
              <div class="flex flex-col sm:flex-row gap-[24px]">
                <div
                  class="sm:w-[430px] mt-[16px] border bg-sk-box002 hover:bg-sk-box001 border-sk-border rounded-[20px] pt-[30px] pl-[30px] h-[278px]"
                >
                  <p class="text-h2-bold md:text-h1-bold md:!font-semibold">
                    국회는 어떤 역사를 <br />
                    가지고 있나요?
                  </p>
                  <p class="text-h3-regular mt-[24px]">
                    국회의 역사를 <br />
                    알아볼까요?
                  </p>
                </div>
                <div
                  class="sm:w-[430px] mt-[16px] border bg-sk-box002 hover:bg-sk-box001 border-sk-border rounded-[20px] pt-[30px] pl-[30px] h-[278px]"
                >
                  <p class="text-h2-bold md:text-h1-bold md:!font-semibold">
                    국회에는 어떤 사람이 <br />
                    있을까요?
                  </p>
                  <p class="text-h3-regular mt-[24px]">
                    국회의 구성원을 <br />
                    알아볼까요?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-[60px] md:mt-[120px]">
          <div class="flex gap-[16px] items-center">
            <div class="w-[4px] h-[50px] bg-sk-primary"></div>
            <div class="text-h1-bold md:text-title-36 md:!text-[42px]">정책</div>
          </div>
          <div class="mt-[40px] grid grid-cols-1 xl:grid-cols-2 gap-[55px]">
            <div>
              <p class="text-h3-bold md:text-h2-bold">대상별 정책</p>
              <div class="mt-[20px] flex gap-[14px] flex-wrap">
                <div
                  v-for="(PolicyTag, i) in groupPolicyTags"
                  :key="i"
                  @click="groupPolicyTagValue = PolicyTag"
                  class="cursor-pointer px-[12px] sm:px-[30px] py-[6px] text-body3-medium sm:text-body2-medium rounded-[100px]"
                  :class="[
                    groupPolicyTagValue === PolicyTag
                      ? 'bg-sk-primary text-white !font-bold'
                      : 'border border-sk-icon-sub hover:bg-sk-box001'
                  ]"
                >
                  {{ PolicyTag }}
                </div>
              </div>
              <div class="mt-[30px] flex flex-col sm:flex-row gap-[30px] sm:gap-[14px]">
                <div class="border border-[#DBDBDB] rounded-[20px] basis-1/2">
                  <div
                    class="w-full rounded-t-[20px] h-[244px] bg-center bg-cover"
                    :style="{ 'background-image': 'url(' + Urls.group1 + ')' }"
                  ></div>
                  <div class="flex flex-col pt-[16px] px-[20px] pb-[25px]">
                    <div
                      class="w-fit text-body3-medium text-sk-default2 rounded-[100px] bg-sk-subScience px-[13px] py-[2px]"
                    >
                      영유아 ∙ 아동 ∙ 청소년
                    </div>
                    <p class="text-body1-semibold mt-[14px] ellipsis-2">
                      학생체력평가 초등 3·4학년도 실시…학교 체육활동 확대
                    </p>
                    <p class="text-body2-medium text-sk-disabled ellipsis-4 mt-[24px]">
                      정부가 오는 2026년부터 학생건강체력평가(팝스) 대상을 기존의 초등학교 5학년
                      이상에서 3학년 이상으로 확대하고 학교스포츠클럽 종목을 학교·지역별 여건에 따라
                      개설하는 등 학생들의 체력 증진을 도모한다. [출처] 대한민국
                      정책브리핑(www.korea.kr)
                    </p>
                  </div>
                </div>
                <div class="border border-[#DBDBDB] rounded-[20px] basis-1/2">
                  <div
                    class="w-full rounded-t-[20px] h-[244px] bg-center bg-cover"
                    :style="{ 'background-image': 'url(' + Urls.group2 + ')' }"
                  ></div>
                  <div class="flex flex-col pt-[16px] px-[20px] pb-[25px]">
                    <div
                      class="w-fit text-body3-medium text-sk-default2 rounded-[100px] bg-sk-subKorean px-[13px] py-[2px]"
                    >
                      청년 ∙ 대학생
                    </div>
                    <p class="text-body1-semibold mt-[14px] ellipsis-2">
                      공공기관 청년인턴 2만 2000명 채용…6개월 인턴 1만명으로 확대
                    </p>
                    <p class="text-body2-medium text-sk-disabled ellipsis-4 mt-[24px]">
                      정부가 내년 공공기관 청년인턴을 총 2만 2000명 채용하고 6개월 인턴도 1만명
                      수준으로 대폭 확대한다. 또한 인턴 기간 중 업무수행 능력과 태도 등을 평가해
                      상위 20% 인턴에게 우수수료증과 우수인턴 추천서를 발급하고, 서류·필기·면접 등
                      우대조치를 차등화한다. [출처] 대한민국 정책브리핑(www.korea.kr)
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="w-[156px] py-[9px] mx-auto mt-[30px] md:mt-[68px] cursor-pointer border border-sk-border rounded-[100px] hover:bg-sk-box001 flex items-center justify-center gap-[14px]"
              >
                <p class="text-body2-medium text-sk-sub">전체 더보기</p>
                <img src="@/assets/icons/more.svg" alt="더보기" />
              </div>
            </div>
            <div>
              <p class="text-h3-bold md:text-h2-bold">지역별 정책</p>
              <div class="mt-[20px] flex gap-[14px] flex-wrap">
                <div
                  v-for="(PolicyTag, i) in regionPolicyTags"
                  :key="i"
                  @click="regionPolicyTagValue = PolicyTag"
                  class="cursor-pointer px-[12px] sm:px-[30px] py-[6px] text-body3-medium sm:text-body2-medium rounded-[100px]"
                  :class="[
                    regionPolicyTagValue === PolicyTag
                      ? 'bg-sk-primary text-white !font-bold'
                      : 'border border-sk-icon-sub hover:bg-sk-box001'
                  ]"
                >
                  {{ PolicyTag }}
                </div>
              </div>
              <div class="mt-[30px] flex flex-col sm:flex-row gap-[30px] sm:gap-[14px]">
                <div class="border border-[#DBDBDB] rounded-[20px] basis-1/2">
                  <div
                    class="w-full rounded-t-[20px] h-[244px] bg-center bg-cover"
                    :style="{ 'background-image': 'url(' + Urls.region1 + ')' }"
                  ></div>
                  <div class="flex flex-col pt-[16px] px-[20px] pb-[25px]">
                    <div
                      class="w-fit text-body3-medium text-sk-default2 rounded-[100px] bg-[#5DBA68] px-[13px] py-[2px]"
                    >
                      충청 ∙ 대전 ∙ 세종
                    </div>
                    <p class="text-body1-semibold mt-[14px] ellipsis-2">
                      과태료 등 행정정보를 제때 알지 못해 불이익을 받은 적이 있나요?
                    </p>
                    <p class="text-body2-medium text-sk-disabled ellipsis-4 mt-[24px]">
                      ▲ 지원대상 <br />대한민국 모든 국민 <br />
                      ▲ 지원내용 <br />국민이 필요한 행정정보를 미리 알려주고, 국민의 질문사항을
                      상담해주는 온라인 개인비서 [출처] 대한민국 정책브리핑(www.korea.kr)
                    </p>
                  </div>
                </div>
                <div class="border border-[#DBDBDB] rounded-[20px] basis-1/2">
                  <div
                    class="w-full rounded-t-[20px] h-[244px] bg-center bg-cover"
                    :style="{ 'background-image': 'url(' + Urls.region2 + ')' }"
                  ></div>
                  <div class="flex flex-col pt-[16px] px-[20px] pb-[25px]">
                    <div
                      class="w-fit text-body3-medium text-sk-default2 rounded-[100px] bg-[#9866D9] px-[13px] py-[2px]"
                    >
                      경상 ∙ 부산 ∙ 울산
                    </div>
                    <p class="text-body1-semibold mt-[14px] ellipsis-2">
                      경남 진주에 새싹삼 재배 ‘발달장애인 특화사업장’ 개소
                    </p>
                    <p class="text-body2-medium text-sk-disabled ellipsis-4 mt-[24px]">
                      ▲ 지원대상 <br />
                      대한민국 모든 국민 <br />
                      ▲ 지원내용 <br />
                      국민이 필요한 행정정보를 미리 알려주고, 국민의 질문사항을 상담해주는 온라인
                      개인비서 [출처] 대한민국 정책브리핑(www.korea.kr)
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="w-[156px] py-[9px] mx-auto mt-[30px] md:mt-[68px] cursor-pointer border border-sk-border rounded-[100px] hover:bg-sk-box001 flex items-center justify-center gap-[14px]"
              >
                <p class="text-body2-medium text-sk-sub">전체 더보기</p>
                <img src="@/assets/icons/more.svg" alt="더보기" />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-[60px] md:mt-[120px]">
          <div class="flex gap-[16px] items-center">
            <div class="w-[4px] h-[50px] bg-sk-primary"></div>
            <div class="text-h1-bold md:text-title-36 md:!text-[42px]">뉴스</div>
          </div>
          <div class="mt-[40px] grid grid-cols-1 xl:grid-cols-2 gap-[55px]">
            <div>
              <p class="text-h3-bold md:text-h2-bold">영상</p>
              <div
                class="mt-[23px] grid grid-cols-1 sm:grid-cols-2 gap-[20px] md:gap-x-[50px] md:gap-y-[35px]"
              >
                <div v-for="(videoNewsItem, i) in videoNewsList" :key="i" class="flex flex-col">
                  <div
                    class="w-full h-[187px] cursor-pointer bg-center bg-cover"
                    :style="{ 'background-image': 'url(' + videoNewsItem.imgUrl + ')' }"
                  ></div>
                  <div class="flex justify-between mt-[6px] items-center">
                    <p class="text-h3-bold md:text-h2-bold truncate max-w-[210px]">
                      {{ videoNewsItem.title }}
                    </p>
                    <p class="text-body2-medium text-sk-info">
                      {{ videoNewsItem.date }}
                    </p>
                  </div>
                  <p class="text-body2-medium text-sk-disabled ellipsis-2 mt-[12px]">
                    {{ videoNewsItem.desc }}
                  </p>
                </div>
              </div>
              <div
                class="w-[156px] py-[9px] mx-auto mt-[30px] md:mt-[68px] cursor-pointer border border-sk-border rounded-[100px] hover:bg-sk-box001 flex items-center justify-center gap-[14px]"
              >
                <p class="text-body2-medium text-sk-sub">전체 더보기</p>
                <img src="@/assets/icons/more.svg" alt="더보기" />
              </div>
            </div>
            <div>
              <p class="text-h3-bold md:text-h2-bold">카드 뉴스</p>
              <div class="mt-[23px] grid grid-cols-1 sm:grid-cols-2 gap-[24px]">
                <div
                  v-for="(cardNewsItem, i) in cardNewsList"
                  :key="i"
                  class="flex flex-col rounded-[15px] border border-[#DBDBDB]"
                >
                  <div
                    class="w-full h-[170px] cursor-pointer bg-center bg-cover rounded-t-[15px]"
                    :style="{ 'background-image': 'url(' + cardNewsItem.imgUrl + ')' }"
                  ></div>
                  <div class="pt-[10px] px-[20px] pb-[30px]">
                    <div
                      class="w-fit text-body3-medium text-sk-default2 rounded-[100px] bg-sk-subScience px-[13px] py-[2px]"
                    >
                      {{ cardNewsItem.tag }}
                    </div>
                    <p class="text-body2-bold mt-[10px] ellipsis-2">
                      {{ cardNewsItem.title }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="w-[156px] py-[9px] mx-auto mt-[30px] md:mt-[68px] cursor-pointer border border-sk-border rounded-[100px] hover:bg-sk-box001 flex items-center justify-center gap-[14px]"
              >
                <p class="text-body2-medium text-sk-sub">전체 더보기</p>
                <img src="@/assets/icons/more.svg" alt="더보기" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      whatUrl: require('@/assets/background/what.png'),
      Urls: {
        group1: require('@/assets/background/group1.png'),
        group2: require('@/assets/background/group2.png'),
        region1: require('@/assets/background/region1.png'),
        region2: require('@/assets/background/region2.png')
      },
      groupPolicyTagValue: '전체',
      groupPolicyTags: [
        '전체',
        '영유아 ∙ 아동 ∙ 청소년',
        '청년 ∙ 대학생',
        '가족 ∙ 중장년',
        '어르신',
        '소득 취약계층',
        '장애인'
      ],
      regionPolicyTagValue: '전체',
      regionPolicyTags: [
        '전체',
        '서울',
        '경기 ∙ 인천',
        '충청 ∙ 대전 ∙ 세종',
        '전라 ∙ 광주 ',
        '강원',
        '경상 ∙ 부산 ∙ 울산',
        '제주'
      ],
      videoNewsList: [
        {
          imgUrl: require('@/assets/background/video1.png'),
          title: '제목입니다',
          date: '2023-12-31',
          desc: '정부가 오는 2026년부터 학생건강체력평가(팝스) 대상을 기존의 초등학교 5학년 이상에서 3학년 이상으로 확대하고 학교스포츠클럽 종목을 학교·지역별 여건에 따라 개설하는 등 학생들의 체력 증진을 도모한다. [출처] 대한민국 정책브리핑(www.korea.kr)'
        },
        {
          imgUrl: require('@/assets/background/video2.png'),
          title: '제목입니다',
          date: '2023-12-31',
          desc: '정부가 오는 2026년부터 학생건강체력평가(팝스) 대상을 기존의 초등학교 5학년 이상에서 3학년 이상으로 확대하고 학교스포츠클럽 종목을 학교·지역별 여건에 따라 개설하는 등 학생들의 체력 증진을 도모한다. [출처] 대한민국 정책브리핑(www.korea.kr)'
        },
        {
          imgUrl: require('@/assets/background/video2.png'),
          title: '제목입니다',
          date: '2023-12-31',
          desc: '정부가 오는 2026년부터 학생건강체력평가(팝스) 대상을 기존의 초등학교 5학년 이상에서 3학년 이상으로 확대하고 학교스포츠클럽 종목을 학교·지역별 여건에 따라 개설하는 등 학생들의 체력 증진을 도모한다. [출처] 대한민국 정책브리핑(www.korea.kr)'
        },
        {
          imgUrl: require('@/assets/background/video1.png'),
          title: '제목입니다',
          date: '2023-12-31',
          desc: '정부가 오는 2026년부터 학생건강체력평가(팝스) 대상을 기존의 초등학교 5학년 이상에서 3학년 이상으로 확대하고 학교스포츠클럽 종목을 학교·지역별 여건에 따라 개설하는 등 학생들의 체력 증진을 도모한다. [출처] 대한민국 정책브리핑(www.korea.kr)'
        }
      ],
      cardNewsList: [
        {
          imgUrl: require('@/assets/background/group1.png'),
          tag: '영유아 ∙ 아동 ∙ 청소년',
          title: '학생체력평가 초등 3·4학년도 실시…학교 체육활동 확대'
        },
        {
          imgUrl: require('@/assets/background/region1.png'),
          tag: '영유아 ∙ 아동 ∙ 청소년',
          title: '학생체력평가 초등 3·4학년도 실시…학교 체육활동 확대'
        },
        {
          imgUrl: require('@/assets/background/region2.png'),
          tag: '영유아 ∙ 아동 ∙ 청소년',
          title: '학생체력평가 초등 3·4학년도 실시…학교 체육활동 확대'
        },
        {
          imgUrl: require('@/assets/background/group2.png'),
          tag: '영유아 ∙ 아동 ∙ 청소년',
          title: '학생체력평가 초등 3·4학년도 실시…학교 체육활동 확대'
        }
      ]
    }
  },
  methods: {
    handleScroll() {
      document.getElementById('my-title').style.transform = `translateY(${window.scrollY / 2}px)`
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    document.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    document.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
