<template>
  <div class="w-full">
    <div class="bg-sk-default md:h-[146px] text-sk-default2 pb-[40px] px-[20px]">
      <div class="container mx-auto flex flex-col md:flex-row justify-between">
        <div>
          <p class="text-h2-bold !font-semibold mt-[29px]">정책공유AI플랫폼</p>
          <p class="text-body4-medium text-sk-disabled mt-[21px]">
            Copyright 2023 Policy Sharing. All Rights Reserved.
          </p>
        </div>
        <div class="flex gap-[20px] md:gap-[60px] pt-[35px] text-body2-bold">
          <p class="underline underline-offset-2 cursor-pointer">개인정보 처리방침</p>
          <p class="text-sk-sub2 cursor-pointer">이용약관</p>
          <p class="text-sk-sub2 cursor-pointer">저작권 안내</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
